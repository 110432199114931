import axios from '../axios';

export const all = (params = {}) => {
  return axios.get('/sale-representatives', params);
};

export const show = (id, params = {}) => {
  return axios.get(`/sale-representatives/${id}`, params);
};

export const create = (payload) => {
  return axios.post('/sale-representatives', payload);
};

export const update = (id, payload) => {
  return axios.put(`/sale-representatives/${id}`, payload);
};

export const destroy = (id) => {
  return axios.delete(`/sale-representatives/${id}`);
};
